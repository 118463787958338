import gql from 'graphql-tag';
import { apolloClient } from '~/nasa_ui/apollo.config';
import { CosmicLocationSlimFragment } from '~/nasa_ui/DAL/location/slim';
import { CosmicOrganizationSlimFragment } from '~/nasa_ui/DAL/organization/slim';
import { dateDisplay } from '~/nasa_ui/utils/dates';
export const eztCosmicLineItemQuery = gql `
  ${CosmicOrganizationSlimFragment}

  fragment LineItemOnEzt on CosmicLineItem {
    asBuiltNumber
    drawingNumber
    id
    isApplied
    itemInstanceId
    lineItemItemClass
    lineItemProjectCode
    lineItemQuantity
    nodeId

    attributes

    changeSet {
      id
      nodeId

      changeSetAuthorities {
        nodes {
          id
          nodeId
        }
      }
    }

    itemInstance {
      id
      inventoryQuantity
      itemDrawingDescription
      lotNumber
      nodeId
      projectCodes
      serialNumber
      side
      size
      subType
    }
  }

  query GetEztLineItems($uuid: UUID!) {
    cosmicDocumentEztById(id: $uuid) {
      nodeId
      number

      eztType

      cosmicLineItems {
        nodes {
          ...LineItemOnEzt
        }
      }

      destinationOrganization {
        ...CosmicOrganizationSlim
      }
    }
  }
`;
export const cosmicLineItemInventoryMatchQuery = gql `
  ${CosmicLocationSlimFragment}

  fragment CosmicLineItemInventoryMatch on Inventory {
    asBuiltNumber
    drawingNumber
    id
    itemDrawingDescription
    lotNumber
    nodeId
    projectCode
    quantity
    serialNumber
    side
    size
    status
    subType
    unitCost

    location {
      ...CosmicLocationSlim
    }
  }

  query GetCosmicLineItemInventoryMatches(
    $itemInstanceId: UUID!
    $lineItemItemClass: InventoryClass
    $lineItemProjectCode: String
    $lineItemQuantity: Float
  ) {
    itemInstanceById(id: $itemInstanceId) {
      nodeId

      inventories(
        filter: {
          subType: { equalTo: $lineItemItemClass }
          projectCode: { equalTo: $lineItemProjectCode }
          quantity: { greaterThanOrEqualTo: $lineItemQuantity }
        }
      ) {
        nodes {
          ...CosmicLineItemInventoryMatch
        }
      }
    }
  }
`;
export const updateCosmicLineItemCsaStatus = (id, status) => {
    if (!id || !status) {
        console.error('Missing input to UpdateCosmicLineItemChangeSetAuthority mutation.');
        return null;
    }
    return apolloClient.mutate({
        mutation: gql `
      mutation UpdateCosmicLineItemChangeSetAuthority($id: Int!, $authorizedDate: Date!, $status: ChangeSetStatus!) {
        updateChangeSetAuthority(
          input: { authorizedDate: $authorizedDate, changeSetAuthorityId: $id, status: $status }
        ) {
          clientMutationId
        }
      }
    `,
        variables: {
            authorizedDate: dateDisplay(new Date()),
            id,
            status
        }
    });
};
